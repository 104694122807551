<template>
  <div>
    <div class="small-intro">
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
    <people-scientific-council
      class="scientific-council-people"
    ></people-scientific-council>
  </div>
</template>
<script>
import IntroPage from "../components/IntroPage.vue";
import PeopleScientificCouncil from "../components/PeopleScientificCouncil.vue";

export default {
  name: "scientific-council",
  components: {
    IntroPage,
    PeopleScientificCouncil,
  },
  data() {
    return {
      currentPageId:
        "9b1acb56-47d0-491c-a5ed-cd6bf50b4d1d",
    };
  },
  methods: {},
};
</script>

<style lang="">
  
</style>