<template>
    <div class="container">
        <div class="row">
            <div v-for="(person, index) in filteredPeople" :key="index">
                <router-link class="person-scientific-council" :to="personLink(person)">
                    <h1>{{person.attributes.field_first_name}}</h1>
                    <h1>{{person.attributes.field_person_last_name}}</h1>
                    <p class="mt-lg-3">{{person.attributes.field_position}}</p>
                    <h6 class="light green mt-lg-2" v-if="person.attributes.field_scientific_council_role">
                        {{person.attributes.field_scientific_council_role}}
                    </h6>
                    <div class="arrow">
                        <img src="../assets/arrow.svg" alt="">
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { slugify } from '@/libs/utils'

export default {
    name:"people",
    computed:{
        people(){
            return this.$store.state.people;
        },
        filteredPeople(){
            return this.people.filter(item => {
                return item.attributes.field_person_role.includes("council")
            })
        },
    },
    methods:{
        personLink(person){
            let personSlug = slugify(this.personName(person));

            return `/person/${personSlug}-${person.attributes.drupal_internal__nid}`
        },
        personName(person) {
            return person.attributes.title;
        },
    },
    mounted(){
        this.$store.dispatch('LOAD_PEOPLE');
    }
};
</script>
<style lang="scss" scoped>
.row {
&>div{
    width: 33.33333%;
}
} 
div:empty{
    display: none;
}
.person-scientific-council {
    border: 1px solid #1DC07E;
    width: 356px;
    height: 356px;
    max-width: 356px;
    max-height: 356px;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    text-align: center;
    padding: 54px 20px 20px 20px;
    margin: 12px auto;
  
    h1 {
        line-height: 2rem;
        text-transform: uppercase;
    }
    p{
        line-height: 1.3rem;
    }
    .arrow{
        margin-top: 2.5rem;
    }
}

@media (max-width:1200px){
    .person-scientific-council {
        width: 296px;
        height: 296px;
        max-width: none;
        max-height: none;
        h1 {
            font-size: 2rem;
            line-height: 1.5rem;
        }
        p {
            margin-top: 1rem
        }
        .arrow {
            margin-top: 1.5rem;
        }
    }
}
@media (max-width: 992px){
    .scientific-council-people {
        .row {
            justify-content: space-between;
            &> div {
            width: auto;
        }   
        }            
    }
}

@media (max-width:768px){
    .scientific-council-people .row > div{
        display: block;
        width: 100%;
        .person-scientific-council{
            width: 100%;
            max-width: none;
            padding: 20px;
            min-height: 247px;
            height: auto;
        }
    }
}
</style>